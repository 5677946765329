import { useState, useEffect } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import {
  getAllPatrimonialZones,
  getAllResidentialZones,
  getAllOthersZones,
} from "../../api/zones.api";
import { deleteConsultantImage } from "../../api/consultants.api";

export default function EmailSign({
  onChange,
  selectedZones,
  backgroundImages,
  setBackgroundImages,
  consultantId,
}) {
  const [zones, setZones] = useState({
    residential: [],
    patrimonial: [],
    others: [],
  });

  const [previewImages, setPreviewImages] = useState({
    high: { zone1: "", zone2: "", zone3: "" },
    medium: { zone4: "", zone5: "", zone6: "" },
    low: { zone7: "", zone8: "", zone9: "" },
  });

  // Cargar las zonas
  useEffect(() => {
    const fetchZones = async () => {
      const residentialZones = await getAllResidentialZones();
      const patrimonialZones = await getAllPatrimonialZones();
      const othersZones = await getAllOthersZones();

      const allZones = {
        residential: residentialZones,
        patrimonial: patrimonialZones,
        others: othersZones,
      };

      setZones(allZones);
    };
    fetchZones();
  }, []);

  // Manejo de cambio de zona seleccionada
  const handleChange = (priority, zoneKey, value) => {
    const updatedZones = {
      ...selectedZones, // Usamos directamente selectedZones
      [priority]: {
        ...selectedZones[priority],
        [zoneKey]: {
          zoneId: value._id, // Asegúrate de que value tenga _id o zoneId
          name: value.name || "", // Asegúrate de que el nombre esté definido
          image: backgroundImages[priority][zoneKey] || "", // Mantén la imagen si ya existe
          zone: value.zone,
        },
      },
    };

    onChange(updatedZones); // Notifica el cambio al componente padre directamente
  };

  // Opciones de zonas para los Dropdowns
  const getAvailableOptions = () => {
    return [
      {
        label: "Residencial",
        items: zones.residential.map((zone) => ({
          label: zone.name,
          value: zone,
        })),
      },
      {
        label: "Patrimonial",
        items: zones.patrimonial.map((zone) => ({
          label: zone.name,
          value: zone,
        })),
      },
      {
        label: "Otros",
        items: zones.others.map((zone) => ({
          label: zone.name,
          value: zone,
        })),
      },
    ];
  };

  // Manejo de subida de imagen
  const handleImageUpload = (priority, zoneKey, e) => {
    const file = e.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const newImage = event.target.result;

      // Actualizamos tanto previewImages como backgroundImages
      const updatedPreviewImages = {
        ...previewImages,
        [priority]: {
          ...previewImages[priority],
          [zoneKey]: newImage, // Actualizamos la imagen en previewImages
        },
      };

      const updatedBackgroundImages = {
        ...backgroundImages,
        [priority]: {
          ...backgroundImages[priority],
          [zoneKey]: newImage, // Actualizamos la imagen en backgroundImages
        },
      };

      setPreviewImages(updatedPreviewImages);
      setBackgroundImages(updatedBackgroundImages);

      const updatedZones = {
        ...selectedZones,
        [priority]: {
          ...selectedZones[priority],
          [zoneKey]: {
            ...selectedZones[priority][zoneKey],
            image: file, // Almacena el archivo
          },
        },
      };

      onChange(updatedZones); // Notificamos al componente padre del cambio
    };
    reader.readAsDataURL(file);
  };

  const handleDeleteImage = (priority, zoneKey) => {
    const imageUrl = backgroundImages[priority][zoneKey];
    const imageType = `${priority}_${zoneKey}_backgroundImage`;

    // Llamamos a la API para eliminar la imagen en el backend
    deleteConsultantImage(consultantId, { toDelete: imageUrl }, imageType).then(
      (res) => {
        // Actualizamos el estado del frontend
        const updatedBackgroundImages = {
          ...backgroundImages,
          [priority]: {
            ...backgroundImages[priority],
            [zoneKey]: "", // Eliminamos la imagen del frontend
          },
        };
        setBackgroundImages(updatedBackgroundImages);

        const updatedZones = { ...selectedZones };
        updatedZones[priority][zoneKey] = {
          ...updatedZones[priority][zoneKey],
          image: "", // Aseguramos que la imagen en las zonas también se elimine
        };

        onChange(updatedZones); // Actualizar las zonas seleccionadas
        alert(`Imagen borrada exitosamente.`);
      }
    );
  };

  // Previsualización y eliminación de imagen
  const handleDeletePreviewImage = (priority, zoneKey) => {
    const updatedPreviewImages = {
      ...previewImages,
      [priority]: {
        ...previewImages[priority],
        [zoneKey]: "",
      },
    };
    setPreviewImages(updatedPreviewImages);

    const updatedBackgroundImages = {
      ...backgroundImages,
      [priority]: {
        ...backgroundImages[priority],
        [zoneKey]: "",
      },
    };
    setBackgroundImages(updatedBackgroundImages);

    const updatedZones = { ...selectedZones };
    updatedZones[priority][zoneKey] = {
      ...updatedZones[priority][zoneKey],
      image: "",
    };

    onChange(updatedZones); // Actualizar zonas seleccionadas
  };

  return (
    <section
      style={{
        width: "100%",
        margin: "0 auto",
        display: "flex",
        justifyContent: "space-evenly",
        gap: "20px",
        flexWrap: "wrap",
      }}
    >
      {["high", "medium", "low"].map((priority, priorityIndex) => (
        <div key={priority} style={{ marginBottom: "1em", width: "100%" }}>
          <h3 style={{ marginBottom: "2rem", textAlign: "left" }}>
            {priority === "high"
              ? "Primera fila"
              : priority === "medium"
              ? "Segunda fila"
              : "Tercera fila"}
          </h3>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "20px",
            }}
          >
            {[
              `zone${priorityIndex * 3 + 1}`,
              `zone${priorityIndex * 3 + 2}`,
              `zone${priorityIndex * 3 + 3}`,
            ].map((zoneKey) => (
              <div
                key={zoneKey}
                style={{
                  border: "1px solid black",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <h4 style={{ marginBottom: "10px" }}>{`Zona - ${zoneKey.replace(
                  "zone",
                  ""
                )}`}</h4>

                <Dropdown
                  value={
                    zones.residential
                      .concat(zones.patrimonial, zones.others)
                      .find(
                        (z) =>
                          z._id === selectedZones[priority][zoneKey]?.zoneId
                      ) || null
                  } // Buscamos el objeto en las zonas para obtener el valor completo
                  options={getAvailableOptions()} // Opciones de todas las zonas
                  optionGroupLabel="label"
                  optionGroupChildren="items"
                  optionLabel="label" // Mostrar el nombre de la zona
                  onChange={(e) => handleChange(priority, zoneKey, e.value)}
                  placeholder="Selecciona una Zona"
                  style={{ width: "100%" }}
                  filter
                />

                <div style={{ marginTop: "20px" }}>
                  <h4 style={{ marginBottom: "10px" }}>Imagen de fondo</h4>
                  {previewImages[priority][zoneKey] ? (
                    <div style={{ position: "relative" }}>
                      <img
                        src={previewImages[priority][zoneKey]}
                        alt={`Fondo ${priority} ${zoneKey}`}
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "cover",
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      />
                      <Button
                        type="button"
                        icon="pi pi-trash"
                        className="large-icon-button"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "#2b363d",
                          border: "none",
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          color: "white",
                        }}
                        onClick={() =>
                          handleDeletePreviewImage(priority, zoneKey)
                        }
                      />
                    </div>
                  ) : backgroundImages[priority][zoneKey] ? (
                    <div style={{ position: "relative" }}>
                      <img
                        src={backgroundImages[priority][zoneKey]}
                        alt={`Fondo ${priority} ${zoneKey}`}
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "cover",
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      />
                      <Button
                        type="button"
                        icon="pi pi-trash"
                        className="large-icon-button"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "#2b363d",
                          border: "none",
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          color: "white",
                        }}
                        onClick={
                          () => handleDeleteImage(priority, zoneKey) // Elimina la imagen del backend
                        }
                      />
                    </div>
                  ) : (
                    <FileUpload
                      name="background"
                      customUpload
                      uploadHandler={(e) =>
                        handleImageUpload(priority, zoneKey, e)
                      }
                      accept="image/*"
                      maxFileSize={20971520}
                      auto
                      chooseLabel="Cargar imagen de fondo"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
}
