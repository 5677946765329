import { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import { useNavigate, Link, useParams } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import Layout from "../Layout/Layout";
import Spinner from "../../components/Spinner/Spinner";
import GoBack from "../../components/GoBack/GoBack";
import AdsTable from "../../components/AdsTable/AdsTable";
import { UserContext } from "../../components/Context/AuthUser";
import Input from "../../components/Input/Input";
import Textarea from "../../components/Textarea/Textarea";
import RequestCard from "../../components/RequestCard/RequestCard";
import Checkboxes from "../../components/CheckBox/Checkboxes";
import { AiOutlinePlus } from "react-icons/ai";
import { BsPersonPlusFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { MdLocationSearching } from "react-icons/md";
import { FaPhoneAlt, FaTrash } from "react-icons/fa";
import { FiSave } from "react-icons/fi";
import { getAllAdsByConsultantId } from "../../api/ads.api";
import {
  createConsultant,
  getConsultantById,
  updateConsultant,
} from "../../api/consultants.api";
import { getAllRequestByConsultantId } from "../../api/requests.api";
import "./ConsultantForm.scss";
import "../../styles/primeReact.scss";
import useWindowSize from "../../hooks/useWindowSize";
import DeleteConsultantModal from "../../components/modals/consultant/delete.consultant.modal";
import EmailSign from "../../components/EmailSign/EmailSign";
import {
  getAllOthersZones,
  getAllPatrimonialZones,
  getAllResidentialZones,
} from "../../api/zones.api";

const ConsultantForm = () => {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const size = useWindowSize();

  const [fullName, setFullName] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("--------");
  const [mobile, setMobile] = useState("--------");
  const [avatar, setAvatar] = useState("");
  const [companyUnitLogo, setCompanyUnitLogo] = useState("");

  const [ads, setAds] = useState([]);
  const [requests, setRequests] = useState([]);
  const [consultantById, setConsultantById] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [residentials, setResidentials] = useState([]);
  const [patrimonials, setPatrimonials] = useState([]);
  const [others, setOthers] = useState([]);
  const [selectedZones, setSelectedZones] = useState({
    high: { zone1: {}, zone2: {}, zone3: {} },
    medium: { zone4: {}, zone5: {}, zone6: {} },
    low: { zone7: {}, zone8: {}, zone9: {} },
  });

  const [backgroundImages, setBackgroundImages] = useState({
    high: { zone1: "", zone2: "", zone3: "" },
    medium: { zone4: "", zone5: "", zone6: "" },
    low: { zone7: "", zone8: "", zone9: "" },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (user.length !== 0) {
      if (id !== undefined) {
        setLoader(true);
        getConsultantById(id).then((res) => {
          setAvatar(res.avatar);
          setCompanyUnitLogo(res.companyUnitLogo);
          setConsultantById(res);
          setLoader(false);
          setSelectedZones({
            high: res.consultantEmailSignZones?.high || {
              zone1: {},
              zone2: {},
              zone3: {},
            },
            medium: res.consultantEmailSignZones?.medium || {
              zone4: {},
              zone5: {},
              zone6: {},
            },
            low: res.consultantEmailSignZones?.low || {
              zone7: {},
              zone8: {},
              zone9: {},
            },
          });
          const initialBackgroundImages = {
            high: {
              zone1: res?.consultantEmailSignZones?.high?.zone1?.image || "",
              zone2: res?.consultantEmailSignZones?.high?.zone2?.image || "",
              zone3: res?.consultantEmailSignZones?.high?.zone3?.image || "",
            },
            medium: {
              zone4: res?.consultantEmailSignZones?.medium?.zone4?.image || "",
              zone5: res?.consultantEmailSignZones?.medium?.zone5?.image || "",
              zone6: res?.consultantEmailSignZones?.medium?.zone6?.image || "",
            },
            low: {
              zone7: res?.consultantEmailSignZones?.low?.zone7?.image || "",
              zone8: res?.consultantEmailSignZones?.low?.zone8?.image || "",
              zone9: res?.consultantEmailSignZones?.low?.zone9?.image || "",
            },
          };
          setBackgroundImages(initialBackgroundImages);
        });
        getAllAdsByConsultantId(id).then((res) => {
          setAds(res);
          setLoader(false);
        });
        getAllRequestByConsultantId(id).then((res) => {
          setRequests(res);
          setLoader(false);
        });
      }
    }
  }, [id, user]);

  useEffect(() => {
    const fetchZones = async () => {
      const residentialZones = await getAllResidentialZones();
      const patrimonialZones = await getAllPatrimonialZones();
      const othersZones = await getAllOthersZones();
      setResidentials(residentialZones);
      setPatrimonials(patrimonialZones);
      setOthers(othersZones);
    };
    fetchZones();
  }, []);

  const handleChangeFile = (file, setter) => {
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setter(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleEmailSignChange = (zones) => {
    setSelectedZones(zones);
  };

  const handleBackgroundImagesChange = (images) => {
    setBackgroundImages(images);
  };

  const onSubmit = (values) => {
    setDisabledButton(false);
    let data = new FormData();
    // Añadir todos los valores al FormData
    for (var key in values) {
      if (key !== "consultantEmailSignZones") {
        data.append(key, values[key]);
      }
    }

    if (avatar) {
      data.append("avatar", avatar);
    }

    if (companyUnitLogo) {
      data.append("companyUnitLogo", companyUnitLogo);
    }
    data.append("id", id);

    data.append("consultantEmailSignZones", JSON.stringify(selectedZones));

    Object.keys(selectedZones).forEach((priority) => {
      Object.keys(selectedZones[priority]).forEach((zoneKey) => {
        const imageFile = selectedZones[priority][zoneKey]?.image;
        if (imageFile && imageFile instanceof File) {
          // Verifica que es un archivo, no base64
          data.append(`${priority}_${zoneKey}_backgroundImage`, imageFile); // Añade el archivo al FormData
        }
      });
    });

    if (!id) {
      createConsultant(data)
        .then((res) => {
          if (res !== undefined) {
            alert(`El consultor ${res.fullName} ha sido creado`);
            setDisabledButton(true);
            navigate("/consultores");
          }
        })
        .catch(() => {
          alert(
            `Algunos de los datos introducidos ya se encuentran en la base de datos, indique otros, por favor`
          );
          setDisabledButton(true);
        });
    } else {
      updateConsultant(data)
        .then((res) => {
          alert(`El consultor ${res.fullName} ha sido actualizado`);
          setDisabledButton(true);
        })
        .catch((err) => {
          console.error(err);
          setDisabledButton(true);
        });
    }
  };

  return (
    <Layout
      subTitle="Consultores"
      subUndertitle={<GoBack />}
      subBreadcrumbs={
        id ? `Consultor ${consultantById.fullName}` : "Crear nuevo consultor"
      }
      footContent={
        <>
          {disabledButton ? (
            <button
              className="buttonForm"
              type="submit"
              form="ConsultantForm"
              style={{ marginRight: 8 }}
            >
              <FiSave
                style={
                  size > 480
                    ? { marginRight: 7 }
                    : {
                        marginRight: 7,
                        transform: "scale(125%)",
                        verticalAlign: "middle",
                      }
                }
              />
              {size > 480 && "Guardar"}
            </button>
          ) : (
            <button
              className="buttonForm__disabled "
              type="submit"
              form="ConsultantForm"
              style={{ marginRight: 8 }}
            >
              <FiSave
                style={
                  size > 480
                    ? { marginRight: 7 }
                    : {
                        marginRight: 7,
                        transform: "scale(125%)",
                        verticalAlign: "middle",
                      }
                }
              />
              {size > 480 && "Guardar"}
            </button>
          )}
          {consultantById !== "" && (
            <DeleteConsultantModal
              showModal={showModal}
              setShowModal={setShowModal}
              ads={ads}
              requests={requests}
              consultant={consultantById}
            />
          )}
          {disabledButton ? (
            <Link className="buttonFormCancel" to="/consultores">
              Cancelar
            </Link>
          ) : (
            <button className="buttonFormCancel">Cancelar</button>
          )}
          {id && user.role !== "Consultor" && (
            <>
              {disabledButton ? (
                <button
                  className="buttonFormDelete"
                  onClick={() => {
                    getAllAdsByConsultantId(id);
                    getAllRequestByConsultantId(id);
                    setShowModal(true);
                  }}
                >
                  <FaTrash
                    style={
                      size > 480
                        ? { marginRight: 7 }
                        : {
                            marginRight: 7,
                            transform: "scale(125%)",
                            verticalAlign: "middle",
                          }
                    }
                  />
                  {size > 480 && "Borrar"}
                </button>
              ) : (
                <button className="buttonFormDelete__disabled">
                  <FaTrash
                    style={
                      size > 480
                        ? { marginRight: 7 }
                        : {
                            marginRight: 7,
                            transform: "scale(125%)",
                            verticalAlign: "middle",
                          }
                    }
                  />
                  {size > 480 && "Borrar"}
                </button>
              )}
            </>
          )}
        </>
      }
    >
      {loader ? (
        <Spinner />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            role: consultantById ? consultantById.role : "Consultor",
            consultantEmail: consultantById
              ? consultantById.consultantEmail
              : "",
            showOnWeb: consultantById ? consultantById.showOnWeb : "Yes",
            consultantPassword: consultantById
              ? consultantById.consultantPassword
              : "",
            consultantToken: consultantById
              ? consultantById.consultantToken
              : "",
            fullName: consultantById ? consultantById.fullName : "",
            avatar: "",
            companyUnitLogo: "",
            consultantMobileNumber: consultantById
              ? consultantById.consultantMobileNumber
              : "",
            consultantPhoneNumber: consultantById
              ? consultantById.consultantPhoneNumber
              : "",
            position: consultantById ? consultantById.position : "",
            profession: consultantById ? consultantById.profession : "",
            office1: consultantById ? consultantById.office1 : "",
            office2: consultantById ? consultantById.office2 : "",
            consultantComments: consultantById
              ? consultantById.consultantComments
              : "",
            consultantEmailSignZones: {
              high: consultantById.consultantEmailSignZones?.high || {},
              medium: consultantById.consultantEmailSignZones?.medium || {},
              low: consultantById.consultantEmailSignZones?.low || {},
            },
          }}
          onSubmit={onSubmit}
        >
          {(formProps) => (
            <Form id="ConsultantForm">
              <div className="ConsultantForm">
                <div className="ConsultantForm__header">
                  <div className="ConsultantForm__header--left">
                    <div className="ConsultantForm__header--img">
                      {avatar ? (
                        <div>
                          <img src={avatar} alt="avatar" />
                        </div>
                      ) : (
                        <div className="ConsultantForm__header--img">
                          <BsPersonPlusFill fontSize="2em" color="#fff" />
                        </div>
                      )}
                    </div>
                    <div className="ConsultantForm__header--info">
                      <h3>{consultantById?.fullName || fullName}</h3>
                      <p>
                        <HiOutlineMail
                          fontSize="1.1em"
                          color="#47535B"
                          style={{ marginRight: 9 }}
                        />
                        {consultantById?.consultantEmail || email}
                      </p>
                      <p>
                        <FaPhoneAlt
                          fontSize="0.85em"
                          color="#47535B"
                          style={{ marginRight: 9 }}
                        />
                        {consultantById?.consultantMobileNumber || mobile}
                      </p>
                    </div>
                  </div>
                  <div className="ConsultantForm__header--company">
                    {companyUnitLogo ? (
                      <div className="ConsultantForm__header--company">
                        <img src={companyUnitLogo} alt="companyUnitLogo" />
                      </div>
                    ) : (
                      <div className="ConsultantForm__header--company">
                        <AiOutlinePlus fontSize="2em" color="#B0B0B0" />
                        <p> Logo departamento</p>
                      </div>
                    )}
                  </div>
                </div>
                <TabView
                  activeIndex={activeIndex}
                  onTabChange={(e) => setActiveIndex(e.index)}
                >
                  <TabPanel header="Detalles">
                    <div className="ConsultantForm__form">
                      <div className="ConsultantForm__form--col">
                        <Input
                          label="Nombre completo"
                          required={true}
                          name="fullName"
                          value={formProps.values.fullName}
                          onChange={(ev) => {
                            if (ev.target.value.trim() === "") setFullName("");
                            else setFullName(ev.target.value);
                            formProps.setFieldValue(
                              ev.target.name,
                              ev.target.value
                            );
                          }}
                        />
                        <Input
                          label="Contraseña"
                          required={true}
                          name="consultantPassword"
                          type="password"
                          value={formProps.values.consultantPassword}
                          onChange={(ev) => {
                            if (
                              !ev.target.value.match(
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,16}$/
                              )
                            ) {
                              setPasswordError(
                                "La contraseña debe tener entre 8 y 16 carácteres, mínimo 1 mayúscula, 1 minúscula, 1 número y un carácter especial"
                              );
                            } else setPasswordError("");
                            formProps.setFieldValue(
                              ev.target.name,
                              ev.target.value
                            );
                          }}
                          error={passwordError}
                        />
                        <Input
                          label="Token Gmail"
                          required={true}
                          name="consultantToken"
                          type="text"
                          value={formProps.values.consultantToken}
                          onChange={(ev) => {
                            setPasswordError("");
                            formProps.setFieldValue(
                              ev.target.name,
                              ev.target.value
                            );
                          }}
                          error={passwordError}
                        />
                        <Input
                          label="Email"
                          required={true}
                          type="email"
                          name="consultantEmail"
                          value={formProps.values.consultantEmail}
                          onChange={(ev) => {
                            if (ev.target.value.trim() === "")
                              setEmail("--------");
                            else setEmail(ev.target.value);
                            formProps.setFieldValue(
                              ev.target.name,
                              ev.target.value
                            );
                          }}
                        />

                        <div className="ConsultantForm__form">
                          <div className="ConsultantForm__form--col">
                            <Input
                              label="Teléfono móvil"
                              required={true}
                              name="consultantMobileNumber"
                              type="text"
                              value={formProps.values.consultantMobileNumber}
                              onChange={(ev) => {
                                if (ev.target.value.trim() === "")
                                  setMobile("--------");
                                else setMobile(ev.target.value);
                                formProps.setFieldValue(
                                  ev.target.name,
                                  ev.target.value
                                );
                              }}
                            />
                          </div>
                          <div className="ConsultantForm__form--col">
                            <Input
                              label="Teléfono fijo"
                              name="consultantPhoneNumber"
                              type="text"
                              value={formProps.values.consultantPhoneNumber}
                              onChange={(ev) =>
                                formProps.setFieldValue(
                                  ev.target.name,
                                  ev.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <Input
                          label="Posición"
                          type="text"
                          name="position"
                          value={formProps.values.position}
                          onChange={(ev) =>
                            formProps.setFieldValue(
                              ev.target.name,
                              ev.target.value
                            )
                          }
                        />
                        <Input
                          label="Ocupación"
                          type="text"
                          name="profession"
                          value={formProps.values.profession}
                          onChange={(ev) =>
                            formProps.setFieldValue(
                              ev.target.name,
                              ev.target.value
                            )
                          }
                        />
                      </div>

                      <div className="ConsultantForm__form--col">
                        <div className="ConsultantForm__form">
                          <div className="ConsultantForm__form--col">
                            <Input
                              label="Oficina 1"
                              name="office1"
                              type="text"
                              value={formProps.values.office1}
                              onChange={(ev) =>
                                formProps.setFieldValue(
                                  ev.target.name,
                                  ev.target.value
                                )
                              }
                            />
                          </div>
                          <div className="ConsultantForm__form--col">
                            <Input
                              label="Oficina 2"
                              name="office2"
                              type="text"
                              value={formProps.values.office2}
                              onChange={(ev) =>
                                formProps.setFieldValue(
                                  ev.target.name,
                                  ev.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <Textarea
                          label="Comentarios"
                          name="consultantComments"
                          value={formProps.values.consultantComments}
                          onChange={(ev) =>
                            formProps.setFieldValue(
                              ev.target.name,
                              ev.target.value
                            )
                          }
                        />
                        {user.role === "Admin" && (
                          <div className="ConsultantForm__form">
                            <div className="ConsultantForm__form--col">
                              <Checkboxes
                                label="Mostrar en la web"
                                type="radio"
                                textA="Si"
                                nameA="showOnWeb"
                                valueA="Yes"
                                onChangeA={(ev) => {
                                  formProps.setFieldValue(
                                    "showOnWeb",
                                    ev.target.value
                                  );
                                }}
                                checkedA={
                                  formProps.values.showOnWeb === "Yes" && true
                                }
                                textB="No"
                                nameB="showOnWeb"
                                valueB="No"
                                onChangeB={(ev) => {
                                  formProps.setFieldValue(
                                    "showOnWeb",
                                    ev.target.value
                                  );
                                }}
                                checkedB={
                                  formProps.values.showOnWeb === "No" && true
                                }
                              />
                            </div>
                            <div className="ConsultantForm__form--col">
                              <Checkboxes
                                label="Rol"
                                type="radio"
                                textA="Consultor"
                                valueA="Consultor"
                                onChangeA={(ev) => {
                                  formProps.setFieldValue(
                                    "role",
                                    ev.target.value
                                  );
                                }}
                                checkedA={
                                  formProps.values.role === "Consultor"
                                    ? true
                                    : ""
                                }
                                textB="Admin"
                                valueB="Admin"
                                onChangeB={(ev) => {
                                  formProps.setFieldValue(
                                    "role",
                                    ev.target.value
                                  );
                                }}
                                checkedB={
                                  formProps.values.role === "Admin" ? true : ""
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="ConsultantForm__form">
                          <div className="ConsultantForm__form--col">
                            <label htmlFor="avatar">Avatar</label>
                            <input
                              type="file"
                              name="avatar"
                              id="avatar"
                              onChange={(ev) => {
                                if (ev.target.files[0].size > 5242880) {
                                  alert(
                                    "La imagen supera los 5 Mb, por favor escoja una de menor tamaño"
                                  );
                                  ev.target.value = "";
                                } else {
                                  handleChangeFile(
                                    ev.target.files[0],
                                    setAvatar
                                  );
                                  formProps.setFieldValue(
                                    ev.target.name,
                                    ev.target.files[0]
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="ConsultantForm__form--col">
                            <label
                              htmlFor="companyUnitLogo"
                              style={{ alignSelf: "start" }}
                            >
                              Logo Unidad de Negocio
                            </label>
                            <input
                              type="file"
                              name="companyUnitLogo"
                              id="companyUnitLogo"
                              onChange={(ev) => {
                                if (ev.target.files[0].size > 5242880) {
                                  alert(
                                    "La imagen supera los 5 Mb, por favor escoja una de menor tamaño"
                                  );
                                  ev.target.value = "";
                                } else {
                                  handleChangeFile(
                                    ev.target.files[0],
                                    setCompanyUnitLogo
                                  );
                                  formProps.setFieldValue(
                                    ev.target.name,
                                    ev.target.files[0]
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Anuncios" disabled={id ? false : true}>
                    <AdsTable ads={ads?.length !== 0 ? ads : []} />
                  </TabPanel>
                  <TabPanel header="Peticiones" disabled={id ? false : true}>
                    {requests?.length === 0 ? (
                      <div>
                        <p style={{ marginTop: 100 }}>
                          Este consultor no tiene peticiones creadas
                        </p>
                        <MdLocationSearching
                          fontSize="2.5em"
                          style={{ marginTop: 20, marginBottom: 100 }}
                        />
                      </div>
                    ) : (
                      requests?.map((request, index) => {
                        return (
                          <RequestCard key={request._id} request={request} />
                        );
                      })
                    )}
                  </TabPanel>
                  <TabPanel header="Firma email" disabled={id ? false : true}>
                    <EmailSign
                      residentials={residentials}
                      patrimonials={patrimonials}
                      others={others}
                      selectedZones={selectedZones}
                      onChange={handleEmailSignChange}
                      backgroundImages={backgroundImages}
                      setBackgroundImages={handleBackgroundImagesChange}
                      consultantId={user?._id}
                    />
                  </TabPanel>
                </TabView>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export default ConsultantForm;
